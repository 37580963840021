import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
// import p1 from 'assests/Photos/Clubs/EcoClub/evcls4/1.jpg';
// import p2 from 'assests/Photos/Clubs/EcoClub/evcls4/2.jpg';
// import p3 from 'assests/Photos/Clubs/EcoClub/evcls4/3.jpg';
// import p4 from 'assests/Photos/Clubs/EcoClub/evcls4/4.jpg';

import Ecoclub2023 from 'views/EnvironmentDay2023/Ecoclub2023';

import SideHealth from 'views/HealthAndWellnesClub/SideHealth';

import { Typography } from '@mui/material';
import Container from 'components/Container';


const Ev_Day_Class4_2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/EcoClub/class4/1.webp`;
  const p2 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/EcoClub/class4/2.webp`;
  const p3 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/EcoClub/class4/3.webp`;
  const p4 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/EcoClub/class4/4.webp`;


  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },


  ];

  return (
    <Main>
   
<Container>

  <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center' >
      Mitti Ki Chitti
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
       Class : 4   &   Date :  09.06.2023
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        On the occasion of the World Environment Day, the students of Class 4 participated in the
activity called ‘Mitti Ki Chitti’ held on Friday, 09 June 2023 in their respective classrooms. The
students were asked to write a letter to The Honorable Prime Minister of India, Shri Narendra
Damodardas Modi, in which they conveyed their concerns about Soil Pollution, its hazardous
consequences and the solutions to conserve one of the richest and finest elements of Nature,
SOIL-EARTH.
The aim of the activity was
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
SAVE SOIL, THE MOTHER OF ALL MOTHERS

<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>

    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <Ecoclub2023 />
             </Box>

    </Grid>
    </Grid> 
    </Container>
    </Main>
   
   
  );
};

export default Ev_Day_Class4_2023;